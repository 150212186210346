<template>
  <b-card>
    <div class="custom-container">
      <b-card v-if="cursoAcademia !== null" class="bg-academia">
        <div class="row">
          <div class="col-12 col-lg-3 d-flex align-items-center">
            <img class="rounded w-100" :src="cursoAcademia.previewImagenUrl ?? '/img/image_cursos_alt.png'"
                 :alt="cursoAcademia.titulo"/>
          </div>
          <div class="col mt-3 mt-md-0 d-flex flex-column" style="gap: 0.5rem">
            <h2 class="font-weight-bold text-capitalize">
              {{ cursoAcademia.titulo }}
            </h2>
            <p class="text-white-50 m-0 h4 custom-descripcion mb-1" style="line-height: 1.5">
              {{ cursoAcademia.descripcion }}
            </p>
            <div class="d-flex align-items-center my-1 flex-wrap">
              <span class="custom-punto d-flex align-items-center font-weight-bold text-success">
                {{ cursoAcademia.categoriaCurso?.descripcion }}
              </span>
              <span class="custom-punto d-flex align-items-center text-capitalize">
                Por {{ cursoAcademia.responsable.nombres }} {{ cursoAcademia.responsable.apellidos }}
              </span>
              <div v-b-tooltip.hover :title="`${ cursoAcademia.privacidad === 1 ? 'Curso público' : 'Curso privado' }`">
                <i :class="`fas fa-${ cursoAcademia.privacidad === 1 ? 'earth-americas' : 'lock' }`"></i>
              </div>
            </div>
            <div class="d-flex flex-wrap align-items-center">
              <span class="custom-punto d-flex align-items-center">
                <i class="fas fa-circle-check mr-1"></i> Última actualización:
                <span class="text-primary ml-1"> {{ cursoAcademia.ultimaActualizacion | formatLastUpdateDate }}</span>
              </span>
              <i class="fas fa-clock mr-1"></i>
              <span class="text-white-50 custom-punto d-flex align-items-center">
                {{ listaClases.reduce((totalHoras, clase) => totalHoras + parseInt(clase.duracion), 0) }}h</span>
              <i class="fas fa-play-circle mr-1"></i>
              <span class="text-white-50">
                {{ listaClases.length }} {{ listaClases.length > 1 ? "clases" : "clase" }}
              </span>
            </div>
          </div>
        </div>
      </b-card>
      <b-card v-if="listaClases.length > 0">
        <div class="row">
          <!-- main content clases -->
          <div class="col-12 col-lg">
            <div class="d-flex flex-column w-100 align-items-center">
              <div class="w-100" v-if="isVideoURLValid">
                <b-embed class="rounded" type="iframe" aspect="16by9" :src="selectedVideo.urlClaseVideo"
                         allowfullscreen></b-embed>
                <div class="row mt-4">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-12 col-lg d-flex align-items-center">
                        <i class="fas fa-circle-play mr-2" style="font-size: 2rem"/>
                        <div>
                          <small class="text-white-50" style="font-size: 1rem">
                            {{ selectedVideo.index + 1 }}/{{ listaClases.length }}
                          </small>
                          <h3 class="text-capitalize">{{ selectedVideo.titulo }}</h3>
                        </div>
                      </div>
                      <div class="col-12 col-lg-3">
                        <vs-button-group>
                          <vs-button class="px-4 py-2" v-b-tooltip.hover :title="previousVideoTitle"
                                     @click="previousVideo" :disabled="selectedVideo.index === 0" flat icon>
                            <i class="fas fa-step-backward"></i>
                          </vs-button>
                          <vs-button class="px-4 py-2" v-b-tooltip.hover :title="nextVideoTitle" flat icon
                                     :disabled=" selectedVideo.index === listaClases.length - 1" @click="nextVideo">
                            <i class="fas fa-step-forward"></i>
                          </vs-button>
                        </vs-button-group>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-4 w-100">
                    <span class="py-2 text-white-50 font-weight-bold border rounded-top px-2" style="font-size: medium">
                      Descripción
                      <i class="fas fa-info-circle"/>
                    </span>
                    <hr class="mt-2"/>
                    <p class="h4" style="line-height: 1.5">{{ selectedVideo.descripcion }}</p>
                  </div>
                </div>
              </div>
              <div class="text-center" v-else>
                <p class="text-danger">Error al cargar el video.</p>
                <i class="fas fa-video-slash" style="opacity: 75%; font-size: 2rem"/>
              </div>
            </div>
          </div>
          <!-- content list clases -->
          <div class="col-4 d-none d-lg-block content-list-class">
            <div class="row list-clases classListContainer" ref="classListContainer">
              <div v-for="(clase, index) in listaClases" :key="clase.id" @click="clickHandle(clase, index)"
                   class="col-12 d-flex align-items-center py-2 bg-dark rounded card-class"
                   :style="{ transition: 'box-shadow 0.3s ease',
                   boxShadow: index === selectedVideo.index ? 'inset 0 0 0 2px #6653d1' : 'none', cursor: 'default'}"
              >
                <div class="row w-100">
                  <div class="col-12 col-xl d-flex justify-content-center align-items-center">
                    <div class="bg-secondary p-3 rounded-circle" style="
                        box-shadow: 0px -1px 33px -8px #d70027;
                        -webkit-box-shadow: 0px -1px 33px -8px #d70027;
                        -moz-box-shadow: 0px -1px 33px -8px #d70027;">
                      <i class="far fa-circle-play" style="font-size: 2rem"></i>
                    </div>
                  </div>

                  <div class="col-12 col-xl-9 my-3 overflow-hidden">
                    <h4 class="font-weight-bold text-capitalize">
                      {{ clase.titulo }}
                    </h4>
                    <p class="text-white-50 m-0 custom-descripcion">
                      {{ clase.descripcion }}
                    </p>
                    <div class="d-flex align-items-center text-white-50 flex-wrap mt-2">
                      <i class="fas fa-circle-check mr-1"></i>
                      <small class="custom-punto d-flex align-items-center">
                        última actualización
                        <span class="ml-1 text-primary">{{clase.ultimaAcatualizacion | formatLastUpdateDate}}</span>
                      </small>
                      <i class="fas fa-clock mr-1"></i>
                      <code class="text-white">{{ clase.duracion }}h</code>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- btn sidebar -->
          <vs-button v-b-toggle.sidebar-variant style="" danger :active="activeSidebar" @click="toggleSidebar"
                     :class="{ 'd-none': !activeSidebar }" class="sidebar-toggle-btn">
            <i class="fas fa-chevron-right"></i>
          </vs-button>

          <!-- sidebar list clases -->
          <b-sidebar id="sidebar-variant" bg-variant="dark" text-variant="light" backdrop shadow
                     @hidden="toggleSidebar">
            <template v-slot:title>
              <small>Clases del curso...</small>
              <small class="text-primary" style="font-size: 1rem">
                {{ selectedVideo.index + 1 }}/{{ listaClases.length }}
              </small>
            </template>
            <div class="row mx-auto">
              <!-- lista completa de videos -->
              <ul class="col">
                <li>
                  <vs-button
                      :flat="index !== selectedVideo.index"
                      :gradient="index === selectedVideo.index"
                      v-for="(clase, index) in listaClases"
                      :key="clase.id"
                      @click="clickHandle(clase, index)"
                      class="w-100 py-2 my-2"
                  >
                    <div class="row w-100 d-flex align-items-center">
                      <!-- icons -->
                      <div class="col-2 position-relative">
                        <i
                            v-if="index === selectedVideo.index"
                            :class="`fas fa-play ${
                            index === selectedVideo.index ? 'text-white' : ''
                          }`"
                            style="font-size: 1.5rem"
                        ></i>
                        <i
                            class="fas fa-circle-play font-weight-bold h1 text-primary position-absolute"
                            style="
                            transform: translate(-50%, -50%);
                            top: 50%;
                            left: 50%;
                            opacity: 50%;
                            font-size: 3rem;
                            overflow: hidden;
                          "
                            v-else
                        >
                        </i>
                      </div>
                      <!-- info class -->
                      <div class="col text-left">
                        <label
                            :class="`font-weight-bold text-capitalize ${
                            index === selectedVideo.index
                              ? 'text-white'
                              : 'text-light'
                          }`"
                        >
                          {{
                            clase.titulo.length > 25
                                ? clase.titulo.substring(0, 25) + "..."
                                : clase.titulo
                          }}
                        </label>
                        <p
                            :class="`m-0  ${
                            index === selectedVideo.index
                              ? 'text-white'
                              : 'text-black'
                          }`"

                            :style="{
                            opacity:
                              index === selectedVideo.index ? '90%' : '50%',
                          }"
                        >
                          {{
                            clase.descripcion.length > 60
                                ? clase.descripcion.substring(0, 60) + "..."
                                : clase.descripcion
                          }}
                        </p>
                      </div>
                    </div>
                  </vs-button>
                </li>
              </ul>
            </div>
          </b-sidebar>
        </div>
      </b-card>
      <div v-else class="d-flex justify-content-center align-items-center font-italic h3">
        <i class="fas fa-spinner fa-spin mr-1"></i>
        Cargando datos...
      </div>
    </div>
  </b-card>
</template>

<script>
import moment_timezone from "moment-timezone";

export default {
  name: "AcademiaVideos",
  data() {
    return {
      activeSidebar: true,
      cursoAcademia: {
        id: null,
        titulo: "",
        descripcion: "",
        previewImagenUrl: null,
        privacidad: null,
        responsableId: null,
        responsable: {},
        certificadoFinalizacion: null,
        categoriaCurso: {},
        clases: [],
        categoriaCursoId: null,
        ultimaActualizacion: null,
      },
      listaClases: [
        {
          id: null,
          titulo: "",
          descripcion: "",
          duracion: "",
          urlClaseVideo: "",
          crusoAcademiaId: null,
          ultimaAcatualizacion: "",
          responsableId: null,
          responsable: {},
          cursoAcademia: {},
        },
      ],
      selectedVideo: null,
      isVideoURLValid: false,
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.cursoAcademia = null;
    this.listaClases = [];
    await this.getListClasesCursoAcademia(this.$route.params.id);
    this.$isLoading(false);
  },
  methods: {
    toggleSidebar() {
      this.activeSidebar = !this.activeSidebar;
    },
    async getListClasesCursoAcademia(cursoId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `ClasesCursosAcademia/All/${cursoId}`,
        });
        if (res.success) {
          this.cursoAcademia = res.data[0].cursoAcademia;
          this.listaClases = res.data;
          this.selectedVideo = {...res.data[0], index: 0};
          await this.validateVideoURL(this.selectedVideo.urlClaseVideo);
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    clickHandle(video, index) {
      this.selectedVideo = video;
      this.selectedVideo.index = index;
      this.validateVideoURL(video.url);

      // Obtener el contenedor de la lista de clases
      const container = this.$refs.classListContainer;

      // Obtener el elemento de la lista correspondiente al video seleccionado
      const element = container.children[index];

      // Calcular la posición del centro del contenedor
      const containerCenter = container.clientHeight / 2;

      // Calcular la posición del elemento seleccionado
      const elementCenter = element.offsetTop + element.clientHeight / 2;

      // Ajustar el scroll para que la clase seleccionada quede centrada verticalmente
      container.scrollTop = elementCenter - containerCenter;
    },
    nextVideo() {
      if (this.selectedVideo.index < this.listaClases.length - 1) {
        this.clickHandle(
            this.listaClases[this.selectedVideo.index + 1],
            this.selectedVideo.index + 1
        );
      }
    },
    previousVideo() {
      if (this.selectedVideo.index > 0) {
        this.clickHandle(
            this.listaClases[this.selectedVideo.index - 1],
            this.selectedVideo.index - 1
        );
      }
    },
    async validateVideoURL(videoURL) {
      try {
        await fetch(videoURL, {method: "HEAD", mode: "no-cors"});
        this.isVideoURLValid = true;
      } catch (error) {
        this.isVideoURLValid = false;
      }
    },
  },
  computed: {
    filteredVideos() {
      return this.listaClases.slice(0, 3); // Retorna solo los primeros 5 videos
    },
    nextVideoTitle() {
      if (this.selectedVideo.index < this.listaClases.length - 1) {
        return this.listaClases[this.selectedVideo.index + 1].titulo;
      }
      return "";
    },
    previousVideoTitle() {
      if (this.selectedVideo.index > 0) {
        return this.listaClases[this.selectedVideo.index - 1].titulo;
      }
      return "";
    },
  },
  filters: {
    formatLastUpdateDate(fechaOriginal) {
      return moment_timezone
          .tz(fechaOriginal, "America/Bogota")
          .format("MMM D, YYYY");
    },
  },
};
</script>

<style scoped>
.content-list-class {
  position: relative;
  max-height: 500px;
  overflow: hidden;
}

.list-clases {
  max-height: 500px;
  overflow-y: scroll;
  gap: 1rem;
  padding-bottom: 200px;
  scroll-behavior: smooth;
}

/*------------- .content-list-class se encuentra en la vista _dark.scss y en custom.css-------------------- */

/* .content-list-class::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px; 
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(16, 36, 68));
  pointer-events: none;
} */

@keyframes show {
  from {
    opacity: 0;
    scale: 25%;
  }
  to {
    opacity: 1;
    scale: 100%;
  }
}

.card-class {
  view-timeline-name: --clase;
  view-timeline-axis: block;

  animation-timeline: --clase;
  animation-name: show;
  animation-range: entry 25% cover 50%;
  animation-fill-mode: both;
}

.sidebar-toggle-btn {
  height: 10rem;
  position: fixed;
  top: 50%;
  transform: translate(-40%, -50%);
  margin-left: -35px;

  /* left: 0; */
  z-index: 0;
}

.sidebar-toggle-btn:hover {
  transform: translate(-20%, -50%);
}

/* .active {
  background-color: #059668;
} */
.active {
  background-color: rgba(5, 150, 104, 0.8);
}

/* Fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

  /* .fade-leave-active in <2.1.8 */
{
  opacity: 0;
}

.circle-badge {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.custom-descripcion {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

/* .bg-academia {
  background-image: url(/img/blob-scene-haikei-l.svg);

  background-size: cover;
  background-position: top;
}
.dark .bg-academia {
  background-image: url('/img/blob-scene-haikei.svg');

  background-size: cover;
  background-position: top;
} */
</style>
